import React from 'react';
import './../App.css';
const Electronics = () => (
  <div id='wowow' className=' w-full' >
            <img classname="w-full" src="header.svg"/>
            <div className="prose m-auto w-full">
              <h1>Additive.fun has an electronics lab creating human interface devices!</h1>
              <div>We've generated over 70,000 miles of air mail in the devlopment of our products.</div>
              <div>A <b>HUGE</b> thanks to Texas instruments, JLCPCB, Espressif, ST electronics, and many others for the components we've included in our designs.</div>
            </div>
            <div class="m-20"/>
            <div className="prose m-auto w-full">
              <h1><b>Our Fav. No Wasted Keys. No Numlock</b>.</h1>

            </div>
            <img src="79.svg"/>
            <div className="prose m-auto w-full">
            <div>79 UNLOCKED — is a compact keyboard that reduces desk space consumption, while retaining all of the keys you need to do powermoves!</div>
              <div>
                We threw in a few features oddly absent from the competition!
                <ul>
                  <li><b>Backlight LEDS</b> - facing down to light up your desk.</li>
                  <li><b>2 Extra Bright LEDS</b> for better space bar illumination.</li>
                  <li><b>Analog & Digital expansion</b> pins so you can solder on knobs, sliders, or a joystick. Or add more keys!</li>
                </ul>
              </div>
            </div>
            <div class="m-20"/>
            <div className="prose m-auto w-full">
            <h1>The Additive Aftertouch MIDI Drumpad. </h1>
            <div>
              This has been a long time in the making (years!). Adjust your composition with an idea that refused to die.
            </div>
            </div>
            <img src="4x4.svg"/>
            <div className="prose m-auto w-full">
              <div class="m-60"/>
              <h1>Wireless, Optical, And Inertial motion capture suit. </h1>
              <div>
                It's something special, and we will have more news about it in the future.
                {/* Rokoko died on us, and unfortunately for them it was past warranty. Our Goal is to build something that kicks Rokoko's ass in every way possible. */}
              </div>
            </div>
            <img src="mcap.svg"/>





  </div>
);

export default Electronics;