import React from 'react';
import {ProfileGrid} from '../components/profile.js';



const profiles = [
  {
    image: '/profile/plus.jpg',
    name: 'CF',
    title: 'Project Manager / UX',
    description: 'Works with clients for exceptional results',
  },
  {
    image: '/profile/plus.jpg',
    name: 'DD',
    title: 'Senior UX Designer',
    description: 'Focuses on the Iconography, Systems, and Copy.',
  },
  {
  image: '/profile/plus.jpg',
  name: 'AS',
  title: 'AR / VR Professional',
  description: 'Prolific creator and social media community manager.'
},
{
  image: '/profile/plus.jpg',
  name: 'RH',
  title: "Motion Designer",
  description: 'Likes to spice things up.'
},
{
  image: '/profile/plus.jpg',
  name: 'NB',
  title: "Developer",
  description: 'Rust & C#'
},
{
  image: '/profile/plus.jpg',
  name: 'WK',
  title: "Developer",
  description: 'Full stack dev'
},
];





const Consulting = () => (
  <div>

    {/* <div className="prose m-20 mx-auto">
      <h1>AR/VR Projects </h1>
    </div>

    <video class="w-25" src="quad2s.mp4" loop="true" muted="true" autoPlay="true" />

    <div className='mx-auto'>
    <ProfileGrid  profiles={profiles} />
    </div> */}
    <img class="w-full py-10" src="heading_external.svg"/>
    <br/>
    <div class=" container lg:w-full">
      <div class="flex flex-col md:flex-row">
        <div class="mx-auto w-full lg:w-1/2 ">
          <h1 class="text-xl mb-4 prose nx-auto">Past Work</h1>
          <video class=" w-25 pb-10 rounded-md" src="quad2s.mp4" loop="true" muted="true" autoPlay="true" />
          <h1 class="text-xl mb-4 prose mx-auto">Current Team</h1>
          <ProfileGrid  profiles={profiles} />
        </div>
        <div class="w-full lg:w-1/2">
          <iframe class="mx-auto" src="https://docs.google.com/forms/d/e/1FAIpQLSfhJ6yVPqdqJXEX9zVlTWt4-QgSP2q3VL_VGkPnoc7I81v0kQ/viewform?embedded=true" width="640" height="1087" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
      </div>
    </div>



    
  </div>
);

export default Consulting;