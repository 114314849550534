import React from 'react';
import './profile.css'; // We'll define styles in this file

const ProfileCard = ({ image, name, title, description }) => {
  return (
    <div className="profile-card">
      <div className='mx-auto'>
        <img src={image} alt={name} className="profile-image" />
      </div>
      <div>
        <div className='profile-name'>{name}</div>
        <div className='profile-title'>{title}</div>
      </div>
      <p className="profile-description">{description}</p>
    </div>
  );
};

const ProfileGrid = ({ profiles }) => {
  return (
    <div className="profile-grid">
      {profiles.map((profile, index) => (
        <ProfileCard key={index} {...profile} />
      ))}
    </div>
  );
};

export { ProfileCard, ProfileGrid };